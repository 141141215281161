import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { SectionLayout } from 'components/common/Widgets/SectionLayout';
import React, { useMemo } from 'react';
import { ListBuilderProvider } from 'components/ListBuilder4/ListBuilderProvider';

import { SaveButton } from 'components/ListBuilder4/SaveButton';
import { ListBuilder } from 'components/ListBuilder4/ListBuilder';
import { ListBuilderState } from 'components/ListBuilder4/listBuilderReducer';
import { ListBuilderFormSchema } from 'components/ListBuilder4/formSchema';
import { useParams } from 'react-router';
import { GetListResponse, prospectsApi } from 'redux/reducers/api/prospects';
import { LoaderBlock } from 'components/common/Svgs/Loader';
import {
  reducerStateFromApi,
  formStateFromApi,
} from 'components/ListBuilder4/utils';
import { ProspectListPermissions } from '@/modules/prospects/types';
import { NoEditPermissionMessage } from '@/components/ListBuilder4/NoEditPermissionMessage';

export function _EditListRoute() {
  const { listUuid } = useParams<{
    listUuid: string;
  }>();

  const {
    data: listData,
    isLoading: listIsLoading,
    isFetching: listIsFetching,
  } = prospectsApi.useGetListQuery(listUuid);

  const {
    data: hydrationData,
    isLoading: hydrationDataIsLoading,
    isFetching: hydrationDataIsFetching,
  } = prospectsApi.useGetHydrateListQuery(listUuid as string, {
    skip: !listUuid,
  });

  const isLoading =
    listIsLoading ||
    hydrationDataIsLoading ||
    listIsFetching ||
    hydrationDataIsFetching;

  const canEdit = listData?.results?.my_permissions?.includes(
    ProspectListPermissions.Write || false
  );

  const hydratedOrganizationMap = useMemo(
    () =>
      hydrationData?.orgs?.reduce((acc, org) => {
        acc[org.uuid.toLocaleLowerCase()] = org.name;
        return acc;
      }, {} as Record<string, string>) || {},
    [hydrationData?.orgs]
  );

  const hydratedProfileMap = useMemo(
    () =>
      hydrationData?.profiles?.reduce((acc, profile) => {
        acc[profile.uuid] = profile.name;
        return acc;
      }, {} as Record<string, string>) || {},
    [hydrationData?.profiles]
  );

  const results = listData?.results as GetListResponse;

  if (isLoading) {
    return <LoaderBlock />;
  }

  const initialProviderState: ListBuilderState = {
    ...reducerStateFromApi(results),
  };

  const initialFormState: ListBuilderFormSchema = {
    ...formStateFromApi(results, hydratedProfileMap, hydratedOrganizationMap),
  };

  if (!isLoading && !canEdit) {
    return <NoEditPermissionMessage />;
  }

  return (
    <ListBuilderProvider
      listUuid={listUuid}
      initialState={initialProviderState}
      initialFormValues={initialFormState}
    >
      <SectionLayout
        title={'Edit list'}
        rightsideToolbar={<SaveButton />}
        slotProps={{
          container: {
            maxWidth: 'xxl',
          },
        }}
      >
        <ListBuilder />
      </SectionLayout>
    </ListBuilderProvider>
  );
}

export default withAppAuthenticationRequired(_EditListRoute);
