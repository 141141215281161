import { Typography } from '@mui/material';
import { SectionLayout } from '../common/Widgets/SectionLayout';

export function NoEditPermissionMessage() {
  return (
    <SectionLayout title="No edit access">
      <Typography>
        You don't have permission to edit this list. Please contact the owner of
        the list.
      </Typography>
    </SectionLayout>
  );
}
